<template>
  <div class="row">
    <div class="col-12">
      <q-form ref="editForm">
        <c-card title="대상공정 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn 
                v-if="editable && isSelect" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="process"
                mappingType="PUT"
                label="저장" 
                icon="save"
                @beforeAction="saveProcess"
                @btnCallback="saveProcessCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-md-6 col-lg-4">
              <template v-if="checkProcessCd">
                <c-process
                  :editable="editable"
                  :disabled="true"
                  label="단위공정"
                  name="processCd"
                  v-model="process.processCd">
                </c-process>
              </template>
              <template v-else>
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="공정"
                  name="processName"
                  v-model="process.processName">
                </c-text>
              </template>
            </div>
            <div class="col-md-6 col-lg-4">
              <c-dept
                :editable="editable"
                :disabled="!isSelect"
                :required="true"
                type="edit"
                label="평가부서"
                name="assessDeptCd"
                v-model="process.assessDeptCd">
              </c-dept>
            </div>
            <div class="col-md-6 col-lg-4">
              <c-field 
                :required="true" 
                :editable="editable"
                :disabled="!isSelect"
                type="user" 
                label="평가담당자" 
                name="assessUserId" 
                v-model="process.assessUserId" />
            </div>
            <div class="col-12">
              <c-tag 
                :editable="editable"
                :disabled="!isSelect"
                :clickable="true"
                itemText="equipmentName"
                itemValue="check"
                label="관련설비"
                name="equipments" 
                v-model="process.equipments"
                @removeTag="removeEquipment"
                @addTag="addEquipment"
                @clickTag="clickTag"
              />
            </div>
            <div class="col-12">
              <c-textarea
                :editable="editable"
                :disabled="!isSelect"
                label="공정특징설명"
                name="processExplainFeature"
                v-model="process.processExplainFeature">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazop-target-process-detail',
  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        drawNodes: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '',
        param: {},
        closeCallback: null,
      },
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd 
        && !this.disabled 
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    checkProcessCd() {
      return this.process.processCd.indexOf('WTE') === -1
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.ram.assessProcess.update.url
      // code setting
      // list setting
    },
    removeEquipment(item) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '제외하시겠습니까? ',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.process.equipments = this.$_.reject(this.process.equipments, item)
        },
        // 취소 callback 함수
        cancelCallback: () => {
          item.check = true
        },
      });
    },
    addEquipment() {
      this.popupOptions.title = '설비 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFacilityPopup;
    },
    closeFacilityPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.process.equipments) {
          this.process.equipments = [];
        }
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.equipments, { equipmentCd: item.equipmentCd }) === -1) {
            this.process.equipments.push({
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
            })
          }
        })
      }
    },
    clickTag(tag) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = tag;
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveProcess() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.process.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveProcessCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
    },
  }
};
</script>
